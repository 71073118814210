<template>
  <div>

    <AppHeader theme="white">
      <template v-slot:left>
        <router-link :to="{name: 'news'}"><img src="@/assets/img/icon_back.png"></router-link>
      </template>
      お知らせ
    </AppHeader>

    <section class="contents">

      <div class="contentInner pb_80">

        <div class="news_detail" v-if="topic">
          <div class="news_detail_title">{{ topic.subject }}</div>
          <div class="news_detail_date">{{ publish_at | formatDate }}</div>
          <div class="news_detail_photo" v-if="topic.image"><img :src="image" alt="ニュース画像"></div>
          <div class="news_detail_text" style="white-space:pre-wrap; word-wrap:break-word;">{{ topic.content }}</div>
          <template v-if="topic.links">
            <div class="news_detail_link" v-for="link in topic.links" :key="`link_${link.id}`">
              <a :href="link.url" :target="link.target">
                <span v-if="link.title">{{ link.title }}</span>
                <span v-else>{{ link.url }}</span>
              </a>
            </div>
          </template>
        </div><!-- .news_detail -->

      </div><!--.contentInner-->

    </section><!--.contents-->


    <AppFooter theme="yellow" fixed></AppFooter>

  </div>
</template>

<script>
import moment from "moment";
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
export default {
  name: 'NewsDetail',
  components: {AppHeader, AppFooter},
  props: { news: Array },
  computed: {
    topic () {
      return this.news.find(topic => topic.id == this.$route.params.news);
    },
    image () {
      return `${process.env.VUE_APP_API_ROOT}../${this.topic.image}`;
    },
    publish_at () {
      return this.topic.start_at || this.topic.created_at;
    }
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD');
    }
  },
}
</script>
